var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',{staticClass:"mt-5 arrival-title",staticStyle:{"font-size":"calc(100% + 1vw + 1vh)","font-weight":"bold"}},[_vm._v(" Related Jewellery ")]),_c('div',{staticClass:"d-flex flex-wrap align-items-start justify-content-center justify-content-md-start mt-4 pb-4 pt-2",staticStyle:{"gap":"1rem"}},_vm._l((_vm.products),function(product,index){return _c('div',{key:index,staticClass:"card"},[_c('div',[_c('img',{staticClass:"card-img-top cursor",attrs:{"src":product.cover_photo,"alt":product.title},on:{"click":function($event){return _vm.toDetailPage(product.slug)}}}),_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.user),expression:"user"}],staticClass:"icon position-absolute rounded-circle bg-light",class:{ 'wish-list': _vm.hasInWishList(product.id) },attrs:{"icon":['fas', 'heart'],"id":("active-wish-" + (product.id))},on:{"click":function($event){return _vm.toggleWish(product.id)}}}),_c('div',{staticClass:"my-2 mx-2",on:{"click":function($event){return _vm.toDetailPage(product.slug)}}},[_c('p',{staticClass:"arrival-body",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(product.title)+" ")]),_c('div',{staticClass:"d-flex flex-column flex-lg-row gap-1"},[(
                                product.productvariants[0].discounted_price
                            )?_c('p',{staticClass:"arrival-body fw-bold"},[_vm._v(" "+_vm._s(_vm.addCommaForPrice( product.productvariants[0] .discounted_price ))+" MMK ")]):_vm._e(),(_vm.soldOutedProducts.includes(product.id))?_c('p',{staticClass:"sold-out text-center"},[_vm._v(" Out of Stock ")]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column flex-lg-row gap-1"},[(product.discounted_type != 0)?_c('p',{staticClass:"arrival-body",class:product.productvariants[0].discounted_price
                                    ? 'text-decoration-line-through'
                                    : 'fw-bold fs-6',staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.addCommaForPrice( product.productvariants[0].final_price ))+" MMK ")]):_vm._e(),(
                                product.productvariants[0]
                                    .discounted_type != 0
                            )?_c('p',{staticClass:"arrival-body",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(product.productvariants[0].discounted_amount)+" "+_vm._s(product.productvariants[0] .discounted_type == 1 ? '%' : 'MMK')+" OFF ")]):_vm._e()])])],1)])}),0),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('main-button',{attrs:{"name":_vm.$t('home.newArrivalArea.button')},on:{"clicked":function($event){return _vm.toListPage('newArrivals')}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }