<template>
    <div style="background:#FCFCFC">
        <div class="container position-relative">
            <router-link
                :to="{ name: 'Blog' }"
                class="position-absolute"
                style="left:2rem;opacity:0.5;color:black;text-decoration:none"
            >
                <span class="triangle"></span> back
            </router-link>

            <div
                class="row d-flex flex-column justify-content-center align-items-center mx-xl-5 py-5"
            >
                <div class="blogShow">
                    <ApolloQuery :query="blogQuery" :variables="{ slug: slug }">
                        <template v-slot="{ result: { data }, isLoading }">
                            <div v-if="isLoading" class="loading apollo">
                                <div class="d-flex justify-content-center">
                                    <div
                                        class="spinner-border text-success"
                                        role="status"
                                    >
                                        <!-- <span class="sr-only">Loading...</span> -->
                                    </div>
                                </div>
                            </div>

                            <div
                                v-else-if="data.blogWithPoint"
                                class="result apollo position-relative"
                            >
                                <div class="blogShow--main">
                                    <h2 class="blogShow__title">
                                        {{ data.blogWithPoint.title }}
                                    </h2>
                                    <small
                                        v-if="data.blogWithPoint.tag"
                                        class="blogShow__guide"
                                        >{{
                                            data.blogWithPoint.tag.name
                                        }}</small
                                    >
                                    <small class="blogShow__date">{{
                                        data.blogWithPoint.published_at
                                    }}</small>
                                    <div
                                        class="video-wrap"
                                        v-if="data.blogWithPoint.video_url"
                                    >
                                        <iframe
                                            width="560"
                                            height="315"
                                            :src="data.blogWithPoint.video_url"
                                            frameborder="0"
                                            allowfullscreen
                                        ></iframe>
                                    </div>
                                    <img
                                        v-else
                                        :src="data.blogWithPoint.photo.file_url"
                                        :alt="data.blogWithPoint.title"
                                        loading="lazy"
                                        width="400"
                                        height="150"
                                    />
                                    <div
                                        class="blogShow__content fs-5"
                                        v-html="data.blogWithPoint.content"
                                    ></div>
                                    <hr />
                                </div>
                            </div>

                            <div v-else class="no-result apollo">
                                No result :(
                            </div>
                        </template>
                    </ApolloQuery>
                </div>
            </div>
        </div>
        <CreateComment />
        <br />
        <CommentList />
        <br />
        <DetailRelated />
        <footer-section />
        <div id="rewardModal">
            <!-- Modal-->
            <transition
                @enter="startTransitionModal"
                @after-enter="endTransitionModal"
                @before-leave="endTransitionModal"
                @after-leave="startTransitionModal"
            >
                <div
                    class="modal fade"
                    style="background: #0000001A;"
                    v-show="isOpenDetailModal"
                    ref="modal"
                    @click.self="isOpenDetailModal = false"
                >
                    <div v-if="!user" class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body text-center fs-3 fw-bold">
                                Get Points
                            </div>
                            <div class="modal-body text-center">
                                Please Login to get points by reading blogs
                            </div>
                            <div class="text-center">
                                <main-button
                                    name="Login"
                                    @clicked="goToLogin"
                                />
                            </div>
                            <div
                                @click="isOpenDetailModal = false"
                                class="close-btn"
                            >
                                <span>Close</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div
                    class="modal fade"
                    style="background: #0000001A"
                    v-show="isRedeem"
                    ref="modal"
                    @click.self="isRedeem = false"
                > -->
            </transition>
            <div class="modal-backdrop fade d-none" ref="backdrop"></div>
        </div>
    </div>
</template>

<script>
import Button from '../../components/reusable/Button.vue';
import blogQuery from '@/graphql/queries/blog/BlogDetails.gql';
// import AddBlogPoint from '@/graphql/mutations/blog/AddBlogPoint.gql';
import Footer from '@/components/home/Footer.vue';
import DetailRelated from '@/components/blog/DetailRelated.vue';
import CommentList from '@/views/comment/List.vue';
import CreateComment from '@/views/comment/Create.vue';
import { getIdFromURL } from 'vue-youtube-embed';
import { mapGetters } from 'vuex';

export default {
    components: {
        'footer-section': Footer,
        'main-button': Button,
        CommentList,
        CreateComment,
        DetailRelated,
    },
    props: ['id', 'slug'],
    data() {
        return {
            blogQuery,
            isOpenDetailModal: false,
        };
    },
    mounted() {
        if (!this.isLoggedIn) {
            this.isOpenDetailModal = true;
        } else {
            this.isOpenDetailModal = false;
        }
        // this.addBlogPoint();
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isLoggedIn: 'auth/isLoggedIn',
        }),
    },
    methods: {
        getVideoId(url) {
            return getIdFromURL(url);
        },
        // addBlogPoint() {
        //     if (!this.user) {
        //         this.isOpenDetailModal = false;
        //     } else {
        //         this.$apollo
        //             .mutate({
        //                 mutation: AddBlogPoint,

        //                 variables: {
        //                     blog_id: 6,
        //                 },
        //             })
        //             .then(response => {
        //                 console.log(response.data);
        //             })
        //             .catch(error => {
        //                 console.log(error);
        //             });
        //         alert('add blog point');
        //     }
        // },
        goToLogin() {
            this.$router.push({
                name: 'Login',
                query: {
                    slug: 'getPoints',
                },
            });
        },
        startTransitionModal() {
            this.$refs.backdrop.classList.toggle('d-block');
            this.$refs.modal.classList.toggle('d-block');
        },
        endTransitionModal() {
            this.$refs.backdrop.classList.toggle('show');
            this.$refs.modal.classList.toggle('show');
        },
    },
};
</script>

<style lang="scss" scoped>
.triangle::after {
    position: absolute;
    top: 50%;
    right: 115%;
    margin-top: -6px;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid black;
    border-bottom: 0.4rem solid transparent;
    border-top: 0.4rem solid transparent;
}
.video-wrap {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    max-width: 1920px;
    margin: 1rem auto;
}
.video-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 1080px;
}
.close-btn {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #333;
    cursor: pointer;
    border-style: none;
    margin: 1rem auto;

    // position: absolute;
    // bottom: -2rem;
    font-size: 12px;
    left: 43%;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    padding: 0.5rem 1rem;
    transition: all 0.5s ease;
    &:hover {
        color: #333;
    }
}
.blogShow {
    img {
        background-color: rgb(206, 203, 203);
        margin: 1rem 0;
        min-height: 150px;
        max-height: 400px;
        height: 300px;
        object-fit: cover;
        width: 100%;
    }

    &__title {
        margin-bottom: 0.5em;
    }

    &__guide {
        background: #e0e0e0;
        border-radius: 20rem;
        margin-right: 10px;
        padding: 5px 10px;
    }

    &__date {
        color: var(--colorGrayDarker);
    }

    &__content {
        margin: 2em 0;

        p {
            margin-bottom: 10px;
        }
    }

    &--main {
        max-width: 768px;
        margin: 0 auto 15px auto;
    }

    &--features {
        .separator {
            margin: 50px 0;
            text-align: center;

            .fa {
                font-size: 5px;
                margin: 0 5px;
            }
        }

        .features {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 350px));
            grid-gap: 2em;
            justify-content: center;
        }
    }
}
</style>
