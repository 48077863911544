<template>
    <div>
        <div class="container">
            <!-- Videos section -->
            <div
                class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between py-3"
            >
                <h3 class="m-0 p-0">Videos</h3>
                <ul class="d-flex flex-wrap m-0 p-0 gap-3" style="">
                    <!-- <li
                        v-for="(videoTag, index) in tagsArray"
                        :key="index"
                        class="list-unstyled cursor tag"
                        :class="{ active: videoTag.id == videoTagIndex }"
                        @click="videoTagIndex = videoTag.id"
                    >
                        {{ videoTag.name }}
                    </li> -->
                    <li
                        @click="getVideoBlog"
                        class="list-unstyled cursor tag"
                        :class="{ active: allVideos }"
                    >
                        All Videos
                    </li>
                    <li
                        v-for="(videoTag, index) in tagsArray"
                        :key="index"
                        class="list-unstyled cursor tag"
                        :class="{ active: videoTag.id == videoTagIndex }"
                        @click="getVideoBlogByTag(videoTag.id)"
                    >
                        {{ videoTag.name }}
                    </li>
                </ul>
            </div>
            <div v-if="videoBlogsArray.length > 0">
                <div
                    class="d-flex flex-column flex-lg-row flex-wrap justify-content-lg-between justify-content-xl-start gap-3"
                >
                    <div
                        class="card cursor col-12 col-lg-5 col-xl-3half"
                        v-for="(videoBlog, index) in videoBlogsArray"
                        :key="index"
                    >
                        <router-link
                            :to="{
                                name: 'BlogDetails',
                                params: {
                                    id: videoBlog.id,
                                    slug: videoBlog.slug,
                                },
                            }"
                            class="text-decoration-none"
                        >
                            <div class="video-wrap">
                                <iframe
                                    width="560"
                                    height="315"
                                    :src="videoBlog.video_url"
                                    frameborder="0"
                                    allowfullscreen
                                ></iframe>
                            </div>
                            <div v-if="videoBlog.video_url">
                                <div
                                    class="tag-name position-absolute rounded bg-light text-dark"
                                >
                                    {{ videoBlog.tag.name }}
                                </div>

                                <div
                                    class="my-2 mx-2 text-dark text-decoration-none"
                                >
                                    <h1
                                        class="fw-bold"
                                        style="font-size: 28px;"
                                    >
                                        {{ videoBlog.title }}
                                    </h1>

                                    <p
                                        class="blog-body fw-bold"
                                        v-html="
                                            videoBlog.content.substring(0, 230)
                                        "
                                    />
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-if="this.hasMorePages" class="text-center">
                <main-button
                    :name="$t('products.showmore.button')"
                    @clicked="showMore"
                />
            </div>
            <div v-else-if="videoBlogsArray.length === 0" class="text-center">
                No Videos to show yet.
            </div>

            <!-- Articles section -->
            <div
                class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between py-3"
            >
                <h3 class="m-0 p-0">Articles</h3>
                <ul class="d-flex flex-wrap m-0 p-0 gap-3">
                    <li
                        @click="getArticleBlog"
                        class="list-unstyled cursor tag"
                        :class="{ active: allArticles }"
                    >
                        All Articles
                    </li>
                    <li
                        v-for="(articleTag, index) in tagsArray"
                        :key="index"
                        class="list-unstyled cursor tag"
                        :class="{ active: articleTag.id == articleTagIndex }"
                        @click="getArticleBlogByTag(articleTag.id)"
                    >
                        {{ articleTag.name }}
                    </li>
                </ul>
            </div>
            <div v-if="articleBlogsArray.length > 0">
                <div
                    class="d-flex flex-column flex-lg-row flex-wrap justify-content-lg-between justify-content-xl-start gap-3"
                >
                    <div
                        class="card cursor col-12 col-lg-5 col-xl-3half"
                        v-for="(articleBlog, index) in articleBlogsArray"
                        :key="index"
                    >
                        <router-link
                            :to="{
                                name: 'BlogDetails',
                                params: {
                                    id: articleBlog.id,
                                    slug: articleBlog.slug,
                                },
                            }"
                            class="text-decoration-none"
                        >
                            <img
                                :src="articleBlog.photo.file_url"
                                :alt="articleBlog.title"
                                class="card-img-top"
                            />
                            <div v-if="articleBlog.photo.file_url">
                                <div
                                    class="tag-name position-absolute rounded bg-light text-dark"
                                >
                                    {{ articleBlog.tag.name }}
                                </div>

                                <div
                                    class="my-2 mx-2 text-dark text-decoration-none"
                                >
                                    <h1
                                        class="fw-bold"
                                        style="font-size: 28px;"
                                    >
                                        {{ articleBlog.title }}
                                    </h1>

                                    <p
                                        class="blog-body fw-bold"
                                        v-html="
                                            articleBlog.content.substring(
                                                0,
                                                230,
                                            )
                                        "
                                    />
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-if="this.hasMorePages" class="text-center">
                <main-button
                    :name="$t('products.showmore.button')"
                    @clicked="showMore"
                />
            </div>
            <div v-else-if="articleBlogsArray.length === 0" class="text-center">
                No Articles to show yet.
            </div>
        </div>
        <!-- <footer-section /> -->
    </div>
</template>

<script>
// import gql from 'graphql-tag';
// import blogsQuery from '@/graphql/queries/blog/Blogs.gql';
import blogsByTagIdQuery from '@/graphql/queries/blog/BlogsByTagId.gql';
// import blogGuidesQuery from '@/graphql/queries/blog/BlogGuides.gql';
import videoBlogs from '@/graphql/queries/blog/VideoBlogs.gql';
import articleBlogs from '@/graphql/queries/blog/ArticleBlogs.gql';
import tagsQuery from '@/graphql/queries/tag/Tags.gql';
import { mapGetters } from 'vuex';
// import BlogCard from '@/components/blog/Details.vue';
import Button from '../../components/reusable/Button.vue';
import { getIdFromURL } from 'vue-youtube-embed';
let videoId = getIdFromURL('https://www.youtube.com/watch?v=24C8r8JupYY');

export default {
    components: {
        // BlogCard,
        'main-button': Button,
    },
    data() {
        return {
            // blogsQuery,
            // blogsByTagIdQuery,
            // blogGuidesQuery,
            tagsQuery,
            // selectedTagId: 'all',
            selectedTagName: 'All Blogs',
            pageNo: 1,
            page: 1,
            count: 6,
            first: 6,
            hasMorePages: false,
            tagName: 'All Articles',
            videoId,
            tagQuery: null,
            videoBlogsArray: [],
            articleBlogsArray: [],
            tagsArray: [],
            videoTagIndex: 0,
            articleTagIndex: 0,
            allVideos: false,
            allArticles: false,
        };
    },
    // created() {
    //     this.fetchAllBlogs();
    //     this.fetchTags();
    // },
    mounted() {
        // this.fetchAllBlogs();
        this.getVideoBlog();
        this.getArticleBlog();
        this.getTags();
    },
    computed: {
        // ...mapState({
        //     buyerguideActive: state => state.blog.buyerguideActive,
        // }),
        ...mapGetters({
            blogs: 'blog/getBlogs',
            user: 'auth/getUser',
        }),
        // tags() {
        //     var blog = this.blogs;
        //     let result = [];
        //     const blogLength = blog.length;
        //     for (let i = 0; i < blogLength; i++) {
        //         result.push(blog[i].tag.name);
        //     }
        //     return result;
        // },
        tagsArrayId() {
            return this.tagsArray.map(tag => {
                return tag.id;
            });
        },
    },
    watch: {
        buyerguideActive: function() {
            this.pageNo = 1;
            this.checkToFetch();
        },
    },
    methods: {
        getTags() {
            this.$apollo
                .query({
                    query: tagsQuery,
                })
                .then(response => {
                    // console.log(response.data.tags);
                    this.tagsArray = response.data.tags;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getVideoBlog() {
            this.allVideos = true;
            this.videoTagIndex = 0;
            this.videoBlogsArray = [];
            this.$apollo
                .query({
                    query: videoBlogs,
                    variables: {
                        first: this.first,
                        page: this.page,
                    },
                })
                .then(response => {
                    // console.log(response.data.videoBlogs.data);
                    this.videoBlogsArray = response.data.videoBlogs.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getArticleBlog() {
            this.allArticles = true;
            this.articleTagIndex = 0;
            this.articleBlogsArray = [];
            this.$apollo
                .query({
                    query: articleBlogs,
                    variables: {
                        first: this.first,
                        page: this.page,
                    },
                })
                .then(response => {
                    // console.log(response.data.articleBlogs.data);
                    this.articleBlogsArray = response.data.articleBlogs.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        // getVideoId(url) {
        //     return getIdFromURL(url);
        // },
        // ...mapActions('blog', ['showBuyerguide', 'hideBuyerguide']),
        // checkToFetch() {
        //     if (this.buyerguideActive) this.fetchGuideBlogs();
        //     else if (this.selectedTagId == 'all') this.fetchAllBlogs();
        //     else this.fetchTagBlogs();
        // },

        // updateSelectedTag(tagValue) {
        //     this.page = 1;

        //     if (tagValue == 'all') {
        //         this.selectedTagId = 'all';
        //         this.selectedTagName = 'All Blogs';
        //         this.fetchAllBlogs();
        //         this.hideBuyerguide();
        //     } else {
        //         this.selectedTagId = tagValue.id;
        //         this.selectedTagName = tagValue.name;
        //         this.fetchTagBlogs();
        //         this.hideBuyerguide();
        //     }
        // },

        // fetchAllBlogs() {
        //     this.$apollo
        //         .query({
        //             query: gql`
        //                 ${blogsQuery}
        //             `,
        //             variables: {
        //                 pageNo: this.pageNo,
        //             },
        //         })
        //         .then(data => {
        //             const { paginatorInfo, data: blogs } = data.data.blogs;
        //             // console.log(data.data.blogs.paginatorInfo);
        //             this.hasMorePages = paginatorInfo.hasMorePages;
        //             this.$store.commit('blog/ADD_BLOGS', {
        //                 blogs: blogs,
        //             });
        //         });
        // },

        // fetchGuideBlogs() {
        //     this.$apollo
        //         .query({
        //             query: gql`
        //                 ${blogGuidesQuery}
        //             `,
        //             variables: {
        //                 count: 6,
        //                 page: this.pageNo,
        //             },
        //         })
        //         .then(data => {
        //             const {
        //                 paginatorInfo,
        //                 data: blogs,
        //             } = data.data.buyerGuidedBlogs;

        //             this.blogs.paginatorInfo = paginatorInfo;
        //             this.blogs.blogs = [...blogs];
        //         });
        // },

        getVideoBlogByTag(id) {
            this.allVideos = false;
            this.videoTagIndex = id;
            this.videoBlogsArray = [];
            this.$apollo
                .query({
                    query: blogsByTagIdQuery,
                    variables: {
                        is_video: true,
                        tag_id: id,
                        pageNo: this.pageNo,
                    },
                })
                .then(response => {
                    // console.log(response.data.blogsByTagId.data);
                    this.responseData = response.data.blogsByTagId.data;
                    this.responseData.map(data => {
                        if (data.video_url != null) {
                            this.videoBlogsArray.push(data);
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getArticleBlogByTag(id) {
            this.allArticles = false;
            this.articleTagIndex = id;
            this.articleBlogsArray = [];
            this.$apollo
                .query({
                    query: blogsByTagIdQuery,
                    variables: {
                        is_video: false,
                        tag_id: id,
                        pageNo: this.pageNo,
                    },
                })
                .then(response => {
                    // console.log(response.data.blogsByTagId.data);
                    this.responseData = response.data.blogsByTagId.data;
                    this.responseData.map(data => {
                        if (data.video_url === null) {
                            this.articleBlogsArray.push(data);
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        // videoTagId() {
        //     this.videoTags.forEach(el => {
        //         return this.tags.push(el.tag.id);
        //     });
        // },

        // showPrev() {
        //     this.pageNo--;
        //     if (this.buyerguideActive) this.fetchGuideBlogs();
        //     else if (this.selectedTagId == 'all') this.fetchAllBlogs();
        //     else this.fetchTagBlogs();
        // },

        // showNext() {
        //     this.pageNo++;
        //     if (this.buyerguideActive) this.fetchGuideBlogs();
        //     else if (this.selectedTagId == 'all') this.fetchAllBlogs();
        //     else this.fetchTagBlogs();
        // },

        // showMore() {
        //     this.pageNo++;

        //     this.$apollo
        //         .query({
        //             query: blogsQuery,

        //             variables: {
        //                 pageNo: this.pageNo,
        //             },
        //         })
        //         .then(response => {
        //             console.log(response, 'fetch new');
        //             const { paginatorInfo, data: blogs } = response.data.blogs;

        //             this.hasMorePages = paginatorInfo.hasMorePages;
        //             blogs.map(blog => {
        //                 this.$store.commit('blog/APPEND_BLOG', blog);
        //             });
        //             this.blogs.paginatorInfo = paginatorInfo;
        //             this.blogs.blogs = [...blogs];
        //             console.log(this.blogs);
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
    },
};
</script>

<style lang="scss" scoped>
.tag {
    background: transparent;
    border-radius: 15px;
    opacity: 1;
    padding: 0 0.5rem;
    color: #333;
}
.active {
    background: #3cb207 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    padding: 0 0.5rem;
    color: #fff !important;
}

@media screen and (min-width: 1200px) {
    .col-xl-3half {
        width: 32.5%;
    }
}
.video-wrap {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    max-width: 1920px;
    margin: 0 auto;
}
.video-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 1080px;
}
.card {
    margin-bottom: 1.5rem;
    padding: 0;
    border: none;
    transition: all 0.2s ease-in-out !important;

    &:hover {
        transform: translateY(-0.5rem);
        box-shadow: none;
        opacity: 0.9;
    }
    .tag-name {
        cursor: pointer;
        padding: 3px 5px;
        top: 10px;
        left: 10px;
    }
    .wish-list {
        color: #77c306;
    }
    img {
        height: 18rem;
        border-radius: 15px;
    }
    .blog-body {
        cursor: pointer;
        padding: 0;
        margin: 0;
    }
}
</style>
