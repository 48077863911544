<template>
    <div class="container text-center py-5">
        <h1 class="fw-bold" style="font-size: calc(100% + 1vw + 1vh)">
            Dreams come true<br /><span style="color: #03A208"
                >Pyae Sone Shin</span
            >
            Mobile App
        </h1>
        <div
            class="d-flex flex-column flex-md-row align-items-center justify-content-center gap-3 mt-5"
        >
            <button class="d-flex align-items-center gap-2 download-button">
                <download-icon />
                <div class="d-flex flex-column align-items-start">
                    <small>Download</small>
                    <span class="fw-bold fs-5">Andriod APK</span>
                </div>
            </button>

            <button
                class="d-flex align-items-center gap-2 download-button"
                @click="andriod"
            >
                <img
                    src="@/assets/img/google_play.png"
                    alt="google play logo"
                    style="width:2.5rem; height:2.5rem"
                />
                <div class="d-flex flex-column align-items-start">
                    <small>Get it on</small>
                    <span class="fw-bold fs-5">Google Play</span>
                </div>
            </button>

            <button
                class="d-flex align-items-center gap-2 download-button"
                @click="ios"
            >
                <img
                    src="@/assets/img/apple_logo.png"
                    alt="apple logo"
                    style="width:2rem; height:2rem"
                />
                <div class="d-flex flex-column align-items-start">
                    <small>Download on the</small>
                    <span class="fw-bold fs-5">App Store</span>
                </div>
            </button>
        </div>
        <div>
            <img
                src="@/assets/img/Mining.png"
                alt="google play logo"
                class="d-none d-lg-block"
                style="background-color: #fff; margin: 0 auto"
            />
        </div>

        <div class="d-flex align-items-center buy-more my-5 p-3">
            <img
                src="@/assets/img/Mining.png"
                class="d-none d-lg-block"
                alt="google play logo"
            />
            <div class="text-start">
                <h1 style="color:#855E0A;font-size:3rem" class="fw-bold">
                    Buy more, Collect in much<br />
                    Let's get Pyae Sone Shin Reward
                </h1>
                <p>
                    ဝတ်ဆင်သူတွေအတွက် သူမတူတဲ့အရည်အသွေးကြောင့်
                    ဂုဏ်တက်စေရမယ်ဆိုတဲ့<br />
                    စိန်နားကပ်တွေမို့အခုပဲ ပြည့်စုံသျှင်မှာ
                    လာရောက်ရွေးချယ်ဆင်မြန်းလိုက်ပါရှင်
                </p>
                <div class="d-flex gap-2">
                    <div class="d-flex flex-column align-items-center">
                        <img
                            src="@/assets/img/Royalty.png"
                            alt="google play logo"
                            style="width:2.5rem; height:2.5rem"
                        />
                        <span>Royalty</span>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                        <img
                            src="@/assets/img/Mining.png"
                            alt="google play logo"
                            style="width:2.5rem; height:2.5rem"
                        />
                        <span>Mining</span>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                        <img
                            src="@/assets/img/Referal1.png"
                            alt="google play logo"
                            style="width:2.5rem; height:2.5rem"
                        />
                        <span>Referal</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center grand-fresher my-5 p-3">
            <img
                src="@/assets/img/Mining.png"
                class="d-none d-lg-block"
                alt="google play logo"
            />
            <div class="text-start">
                <h1 style="color:#00563C;font-size:3rem" class="fw-bold">
                    Get Grand Fresher Reward<br />
                    Up to 50,000 MMK
                </h1>
                <p>
                    ဝတ်ဆင်သူတွေအတွက် သူမတူတဲ့အရည်အသွေးကြောင့်
                    ဂုဏ်တက်စေရမယ်ဆိုတဲ့<br />
                    စိန်နားကပ်တွေမို့အခုပဲ ပြည့်စုံသျှင်မှာ
                    လာရောက်ရွေးချယ်ဆင်မြန်းလိုက်ပါရှင်
                </p>
            </div>
        </div>

        <div class="d-flex align-items-center notifications my-5 p-3">
            <img
                src="@/assets/img/Mining.png"
                class="d-none d-lg-block"
                alt="google play logo"
            />
            <div class="text-start">
                <h1 style="color:#1455C3;font-size:3rem" class="fw-bold">
                    Get Track Notification<br />
                    for your orders
                </h1>
                <p>
                    ဝတ်ဆင်သူတွေအတွက် သူမတူတဲ့အရည်အသွေးကြောင့်
                    ဂုဏ်တက်စေရမယ်ဆိုတဲ့<br />
                    စိန်နားကပ်တွေမို့အခုပဲ ပြည့်စုံသျှင်မှာ
                    လာရောက်ရွေးချယ်ဆင်မြန်းလိုက်ပါရှင်
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        andriod() {
            window.location.href =
                'https://play.google.com/store/apps/details?id=com.pyaesoneshin';
        },
        ios() {
            window.location.href =
                'https://apps.apple.com/mm/app/pyae-sone-shin/id1570012216';
        },
    },
};
</script>

<style lang="scss" scoped>
.download-button {
    border: 1px solid #333;
    background-color: #fff;
    border-radius: 13px;
    padding: 0.2rem 0.5rem;
    opacity: 1;
    transition: 0.5s;
    &:hover {
        opacity: 0.8;
        border: 1px solid #49bb09;
        background-color: #49bb09;
        color: #fff;
    }
}
.buy-more {
    background: transparent linear-gradient(147deg, #ebe5bb 0%, #ebe5bb33 100%)
        0% 0% no-repeat padding-box;
    border: 1px solid #0000001a;
    border-radius: 10px;
    opacity: 1;
}
.grand-fresher {
    background: transparent linear-gradient(147deg, #ceebbb 0%, #ceebbb33 100%)
        0% 0% no-repeat padding-box;
    border: 1px solid #0000001a;
    border-radius: 10px;
    opacity: 1;
}
.notifications {
    background: transparent linear-gradient(147deg, #c8d5f2 0%, #c8d5f233 100%)
        0% 0% no-repeat padding-box;
    border: 1px solid #0000001a;
    border-radius: 10px;
    opacity: 1;
}
@media (min-width: 280px) and (max-width: 767.98px) {
    h1 {
        font-size: 2rem !important;
    }
}
</style>
