var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between py-3"},[_c('h3',{staticClass:"m-0 p-0"},[_vm._v("Videos")]),_c('ul',{staticClass:"d-flex flex-wrap m-0 p-0 gap-3"},[_c('li',{staticClass:"list-unstyled cursor tag",class:{ active: _vm.allVideos },on:{"click":_vm.getVideoBlog}},[_vm._v(" All Videos ")]),_vm._l((_vm.tagsArray),function(videoTag,index){return _c('li',{key:index,staticClass:"list-unstyled cursor tag",class:{ active: videoTag.id == _vm.videoTagIndex },on:{"click":function($event){return _vm.getVideoBlogByTag(videoTag.id)}}},[_vm._v(" "+_vm._s(videoTag.name)+" ")])})],2)]),(_vm.videoBlogsArray.length > 0)?_c('div',[_c('div',{staticClass:"d-flex flex-column flex-lg-row flex-wrap justify-content-lg-between justify-content-xl-start gap-3"},_vm._l((_vm.videoBlogsArray),function(videoBlog,index){return _c('div',{key:index,staticClass:"card cursor col-12 col-lg-5 col-xl-3half"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                            name: 'BlogDetails',
                            params: {
                                id: videoBlog.id,
                                slug: videoBlog.slug,
                            },
                        }}},[_c('div',{staticClass:"video-wrap"},[_c('iframe',{attrs:{"width":"560","height":"315","src":videoBlog.video_url,"frameborder":"0","allowfullscreen":""}})]),(videoBlog.video_url)?_c('div',[_c('div',{staticClass:"tag-name position-absolute rounded bg-light text-dark"},[_vm._v(" "+_vm._s(videoBlog.tag.name)+" ")]),_c('div',{staticClass:"my-2 mx-2 text-dark text-decoration-none"},[_c('h1',{staticClass:"fw-bold",staticStyle:{"font-size":"28px"}},[_vm._v(" "+_vm._s(videoBlog.title)+" ")]),_c('p',{staticClass:"blog-body fw-bold",domProps:{"innerHTML":_vm._s(
                                        videoBlog.content.substring(0, 230)
                                    )}})])]):_vm._e()])],1)}),0)]):_vm._e(),(this.hasMorePages)?_c('div',{staticClass:"text-center"},[_c('main-button',{attrs:{"name":_vm.$t('products.showmore.button')},on:{"clicked":_vm.showMore}})],1):(_vm.videoBlogsArray.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" No Videos to show yet. ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between py-3"},[_c('h3',{staticClass:"m-0 p-0"},[_vm._v("Articles")]),_c('ul',{staticClass:"d-flex flex-wrap m-0 p-0 gap-3"},[_c('li',{staticClass:"list-unstyled cursor tag",class:{ active: _vm.allArticles },on:{"click":_vm.getArticleBlog}},[_vm._v(" All Articles ")]),_vm._l((_vm.tagsArray),function(articleTag,index){return _c('li',{key:index,staticClass:"list-unstyled cursor tag",class:{ active: articleTag.id == _vm.articleTagIndex },on:{"click":function($event){return _vm.getArticleBlogByTag(articleTag.id)}}},[_vm._v(" "+_vm._s(articleTag.name)+" ")])})],2)]),(_vm.articleBlogsArray.length > 0)?_c('div',[_c('div',{staticClass:"d-flex flex-column flex-lg-row flex-wrap justify-content-lg-between justify-content-xl-start gap-3"},_vm._l((_vm.articleBlogsArray),function(articleBlog,index){return _c('div',{key:index,staticClass:"card cursor col-12 col-lg-5 col-xl-3half"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                            name: 'BlogDetails',
                            params: {
                                id: articleBlog.id,
                                slug: articleBlog.slug,
                            },
                        }}},[_c('img',{staticClass:"card-img-top",attrs:{"src":articleBlog.photo.file_url,"alt":articleBlog.title}}),(articleBlog.photo.file_url)?_c('div',[_c('div',{staticClass:"tag-name position-absolute rounded bg-light text-dark"},[_vm._v(" "+_vm._s(articleBlog.tag.name)+" ")]),_c('div',{staticClass:"my-2 mx-2 text-dark text-decoration-none"},[_c('h1',{staticClass:"fw-bold",staticStyle:{"font-size":"28px"}},[_vm._v(" "+_vm._s(articleBlog.title)+" ")]),_c('p',{staticClass:"blog-body fw-bold",domProps:{"innerHTML":_vm._s(
                                        articleBlog.content.substring(
                                            0,
                                            230
                                        )
                                    )}})])]):_vm._e()])],1)}),0)]):_vm._e(),(this.hasMorePages)?_c('div',{staticClass:"text-center"},[_c('main-button',{attrs:{"name":_vm.$t('products.showmore.button')},on:{"clicked":_vm.showMore}})],1):(_vm.articleBlogsArray.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" No Articles to show yet. ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }